.wrapper {
  padding: 20px;
}

.bar {
  display: flex;
  gap: 10px;
}

.star {
  color: #ffc51b;
  font-size: 20px;
}

.select {
  width: 100%;
}

.content {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card {
  border: 1px solid #d9d9d9;
  border-radius: 20px;

  .top {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 40px;

    .avatar {
      width: 100px;
      height: 100px;
      background-size: cover;
      background-position: center;
      border-radius: 20px;
    }

    .info {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      text-align: right;
      gap: 4px;

      .star {
        margin-right: 10px;
      }

      .name {
        font-size: 20px;
        font-weight: bold;
      }

      .category {
        color: #888;
      }
    }
  }

  .center {
    padding: 10px 10px 20px 15px;
    border-top: 1px solid #d9d9d9;

    .helps {
      font-size: 14px;
      font-weight: bold;
    }

    .location, .format {
      font-size: 14px;
      margin-bottom: 10px;
    }

    .categories {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin-bottom: 10px;
    }

    .about {
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 15px;
    }

    .button {
      width: 100%;
    }
  }
}
