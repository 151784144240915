.wrapper {
  padding: 20px;
}

.back {
  margin-bottom: 15px;
  width: 100%;
}

.top {
  display: flex;
  align-items: center;
  gap: 40px;
  padding-bottom: 10px;

  .avatar {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    border-radius: 20px;
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    gap: 4px;

    .name {
      font-size: 20px;
      font-weight: bold;
    }

    .category {
      color: #888;
    }
  }
}

.center {
  border-top: 1px solid #d9d9d9;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 10px;

  .section {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .title {
      font-weight: bold;
    }

    .value {
      line-height: 1.4;

      a {
        color: #1677ff;
        text-decoration: none;
      }
    }

    .categories {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
    }
  }
}

.star {
  color: #ffc51b;
  font-size: 20px;
}
